// ITCSS ====================
// ==========================
// settings - global, colors
// tools - functions, mixins
// generic - box-sizing, normalize, reset
// elements - headings, links, html tags
// objects - wrappers, grids
// components - site-nav, buttons, carousels
// trumps - clearfix, utilities, ie8
// ==========================

@import "bootstrap/bootstrap"; 
// @import "settings.colors";

// @import "objects.wrappers";
// @import "components.candidates-center";
// @import "components.candidates-lower";
// @import "components.candidates-left";
// @import "components.party-center";
// @import "components.party-lower";
// @import "components.candidate-center";
// @import "trumps.utilities.scss";

// @import "generic.reset";

@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 400;
  src: url("http://output.webupdates.tv/stylesheets/fonts/avenir-next-regular.eot?#iefix-ypioc1") format("embedded-opentype"), 
  	   url("http://output.webupdates.tv/stylesheets/fonts/avenir-next-regular.woff?-ypioc1") format("woff"), 
  	   url("http://output.webupdates.tv/stylesheets/fonts/avenir-next-regular.ttf?-ypioc1") format("truetype"), 
  	   url("http://output.webupdates.tv/stylesheets/fonts/avenir-next-regular.svg?-ypioc1") format("svg");
}
@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 700;
  src: url("http://output.webupdates.tv/stylesheets/fonts/avenir-next-demi.eot?#iefix-ypioc1") format("embedded-opentype"), 
  	   url("http://output.webupdates.tv/stylesheets/fonts/avenir-next-demi.woff?-ypioc1") format("woff"), 
  	   url("http://output.webupdates.tv/stylesheets/fonts/avenir-next-demi.ttf?-ypioc1") format("truetype"), 
  	   url("http://output.webupdates.tv/stylesheets/fonts/avenir-next-demi.svg?-ypioc1") format("svg");
}

body {
  font-family: 'Avenir Next', sans-serif;
  background-color: #ebebeb;
}

div {
	box-sizing: border-box;
}

h1 {
	color: #7f7f7f;
	text-transform: uppercase;

	strong {
		color: #545454;
	}
}

.schedule-wrapper {
	background-color: white;
	padding: 10px;
	min-height: 100vh;
}

.thead-red {
	background-color: #7f7f7f;
	color: white;
}

.dates {
	margin-top: 1em;
	ul {
		list-style: none;
		display: flex;
		padding: 0;

		li {
			background-color: #f5f5f6;
			flex: 1 1 auto;
			text-align: center;
			margin: 0 5px;
			text-transform: uppercase;
			padding-top: 2px;
			padding-bottom: 2px;

			strong {
				display: block;
			}
		}

		li.active {
			background-color: #7f7f7f;
			color: white;
		}
	}
}

.header {
	background-color: #da291c;
	position: fixed;
	width: 100%;
	z-index: 999;
}

.league {
	margin-top: 1em;
	list-style: none;
	padding: 0;


	li {
		color: white;
		display: inline-block;
		margin-right: 5px;

		a {
			background-color: #c42519;
			color: white;
			padding: 5px 10px;
			display: inline-block;
		}

		a.active {
		}
	}
}

.logo-superpak {
	background-image: url('../images/sprite-sportspak.png'); 
	display: inline-block;
	width: 75px;
	height: 48px;

	&.USCB {
		background-position: 0 0;
	}

	&.MLB {
		background-position: 75px 0 ;
	}

	&.NBA {
		background-position: 150px 0 ;
	}

	&.NHL {
		background-position: 225px 0 ;
	}

	&.OHL {
		background-position: 300px 0 ;
	}

	&.NFL {
		background-position: 375px 0 ;
	}
}

.table td {
	vertical-align: middle;
}

/*.background {
	background: transparent url('../images/election-bg.png') no-repeat 0 0;
	height: 1080px;
	left: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 1920px;
	z-index: 2;
}
.wrapper {
	height: 1080px;
	left: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 1920px;
	z-index: 3;
}
.lower-background {
	// background: transparent url('../images/election-bg.png') no-repeat 0 -815px;
	height: 235px;
	left: 0;
	overflow: hidden;
	position: absolute;
	// bottom: 0;
	width: 1920px;
	top: 845px;
	z-index: 1;

	.video {
		position: relative;
		height: 235px;
		// top: -815px;
		// top: -845px;
		bottom: 0;

		video {
			position: absolute;
			top: -845px;
		}
	}
}
.box-container {
	height: 1080px;
	left: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 1920px;
	z-index: 2;
}


// Election Logo
.logo { background: transparent url('../images/logo-local-results-e.png') no-repeat 0 0; }
.lang-f .logo { background: transparent url('../images/logo-local-results-f.png') no-repeat 0 0; }


// Top Bar
.top-bar {
  background: transparent url('../images/top-bar-bg.png') no-repeat 0 0;
  position: absolute;
  width: 1920px;
  height: 75px;
  top: 100px;
  // animation: slide-in 1s .5s cubic-bezier(0, .9, .3, 1) forwards;
  
  .riding {
  	border-left: 10px solid #fff;
    color: #fff;
    font-size: 54px;
    line-height: 75px;
    margin-left: 165px;
    padding: 5px 0 5px 15px;
    position: relative;
  }
}

.l-left-bar {
	position: relative;

	.logo {
		position: absolute;
		background-size: cover;
		width: 360px;
		height: 100px;
		top: 915px;
		left: 174px;
	}
}

.left-bar{
	position: absolute;
	top: 195px;
	left: 174px;
	// z-index: 10;

	

	.polls {
		background-color: #424043;
		color: #fff;
		font-size: 35px;
		padding: 8px 10px;
		line-height: 35px;
		text-align: right;
		margin-bottom: 5px;
	}

	.candidates {
		display: flex;
		flex-direction: column;
		align-content: flex-start;
	}

	.item {
		flex: 1;
		padding-bottom: 10px;
	}

	.candidate {
		background: transparent url('../images/candidate-default.png') no-repeat 0 0;
		background-size: cover;
		position: relative;
  		height: 150px;
  		width: 400px;
	}


	.candidate-LIB, .candidate-PLC { background-image: url('../images/candidate-LIB.png'); }
	.candidate-CON, .candidate-PCC { background-image: url('../images/candidate-CON.png'); }
	.candidate-NDP, .candidate-NPD { background-image: url('../images/candidate-NDP.png'); }
	.candidate-GRN, .candidate-PV { background-image: url('../images/candidate-GRN.png'); }
	.candidate-PC { background-image: url('../images/candidate-PC.png'); }
	.candidate-Other, .candidate-IND { background-image: url('../images/candidate-Other.png'); }

	.headshot {
		position: absolute;
		height: 150px;
		width: 113px;
		z-index: 1; 
		overflow: hidden;
  
		.elected {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 40px;
		}
	}

	ul.text{
		position: absolute;
		z-index: 10;
		height: 135px;
		width: 400px;
		top: 10px;

		li {
			font-size: 35px;
			line-height: 35px;
			padding: 10px 10px 0 10px;
			margin: 0;
		}


		.name {
			color: #fff;
			text-transform: uppercase;
			text-shadow: 2px 2px #333;
		}

		.party {
			left: 120px;
		}

		.party em {
			font-style: normal;
			color: #fff;
			text-shadow: 2px 2px #333;
			font-size: 28px;
  		}
	}

}



.lower-third {

	position: absolute;
	height: 265px;
	bottom: 0;
	z-index: 2;

	.logo {
		position: absolute;
		background-size: cover;
		width: 270px;
		height: 75px;
		top: 110px;
		left: 125px;
		z-index: 10;
	}

	.ticker {
		height: 45px;
		background-color: #333;
		width: 1920px;
		margin-bottom: 3px;
		padding: 0 300px 0 420px;

		p {
			color: #fff;
			font-size: 35px;
			padding: 5px 0;
			line-height: 35px;
		}
	}

	.info {
		position: relative;
		height: 45px;
		background-color: #333;
		width: 1920px;
		padding: 0 300px 0 420px;

		p {
			color: #fff;
			font-size: 35px;
			padding: 5px 0;
			line-height: 35px;
		}

		.polls {
			color: #fff;
			border-left: 3px solid #fff;
			background-color: #da291c;
			position: absolute;
			right: 300px;
			top: 0;
			font-size: 35px;
			padding: 5px 10px;
			line-height: 35px;
		}
	}

	.candidates {
		// background: transparent url('../images/logo-local-results.png') no-repeat 100px 50%;
		display: flex;
		flex-direction: row;
		//padding: 0 345px 0 420px;
		width: 1255px;
		overflow: hidden;
		position: absolute;
		left: 420px;
	}

	.item {
		flex: 0 0 270px;
  		margin-right: 40px;
	}

	.candidate {
		background: #fff url('../images/candidate-lower-default.png');
  		height: 120px;
  		width: 270px;
  		position: relative;
  		
	}

	.candidate-LIB, .candidate-PLC { background-image: url('../images/candidate-lower-LIB.png'); }
	.candidate-CON, .candidate-PCC { background-image: url('../images/candidate-lower-CON.png'); }
	.candidate-NDP, .candidate-NPD { background-image: url('../images/candidate-lower-NDP.png'); }
	.candidate-GRN, .candidate-PV { background-image: url('../images/candidate-lower-GRN.png'); }
	.candidate-PC { background-image: url('../images/candidate-lower-PC.png'); }
	.candidate-Other, .candidate-IND { background-image: url('../images/candidate-lower-Other.png'); }

	.headshot {
		position: absolute;
		height: 120px;
		width: 90px;
		z-index: 1; 
		overflow: hidden;
  
		.elected {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 30px;
		}
	}

	ul.text{
		position: absolute;
		z-index: 10;
		height: 120px;
		width: 270px;

		li {
			font-size: 28px;
			line-height: 28px;
			padding: 6px 10px;
			margin: 0;
			width: 270px;
			white-space: nowrap; 
			overflow: hidden;
			text-overflow: ellipsis; 
		}

		.name {
			color: #fff;
			text-shadow: 2px 2px #333;
			text-transform: capitalize;

			strong {
				display: block;
				font-weight: normal;
				text-transform: uppercase;
			}
		}

		.party {
			text-transform: uppercase;
			left: -110px !important;
		}

		.party em {
			font-style: normal;
			color: #fff;
			text-shadow: 2px 2px #333;
			font-size: 20px;
  		}
	}
	
}







.controls {
	padding: 20px;
}

.hidden {
	display: none;
}

// Layout full page listing all candidates ==================================
// ==========================================================================
.l-full-page-all {

	.logo {
		position: absolute;
		background-size: cover;
		width: 360px;
		height: 100px;
		top: 915px;
		left: 175px;
	}

	.polls {
		background-color: #da291c;
		color: #fff;
		font-size: 35px;
		padding: 8px 10px;
		line-height: 35px;
		text-align: right;
		margin-bottom: 5px;
		position: absolute;
		right: 175px;
		top: 12px;
	}

	.candidates {
		position: absolute;
		width: 1610px;
		height: 815px;
		left: 155px;
		top: 200px;
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
	}

	.item {
		flex: 0 1 25%;
		padding: 0 20px 40px 20px;

	}

	.candidate {
		background: transparent url('../images/candidate-default.png') no-repeat 0 0;
		background-size: cover;
		position: relative;
		height: 137px;
	}

	.candidate-LIB, .candidate-PLC { background-image: url('../images/candidate-LIB.png'); }
	.candidate-CON, .candidate-PCC { background-image: url('../images/candidate-CON.png'); }
	.candidate-NDP, .candidate-NPD { background-image: url('../images/candidate-NDP.png'); }
	.candidate-GRN, .candidate-PV { background-image: url('../images/candidate-GRN.png'); }
	.candidate-PC { background-image: url('../images/candidate-PC.png'); }
	.candidate-Other, .candidate-IND { background-image: url('../images/candidate-Other.png'); }

	.headshot {
		position: absolute;
		height: 137px;
		width: 28.125%;
		z-index: 1; 
		overflow: hidden;

		.elected {
			position: absolute;
			z-index: 10;
			left: 0;
			bottom: 0;
			width: 40%;
		}
	}

	ul.text{
		height: 123px;
		width: 100%;
		top: 14px;

		li {
			font-size: 35px;
			line-height: 35px;
			padding: 3px 10px 0 10px;
		}

		.party {
			text-transform: uppercase;
			left: 110px;
		}

		.party em {
			font-size: 22px;
  		}
	}
}
// **************************************************************************
// **************************************************************************

.candidate 	{

	ul.text{
		position: absolute;
		z-index: 10;

		li {
			margin: 0;
		}

		.votes {
			font-weight: bold;
		}

		.name {
			color: #fff;
			text-transform: capitalize;
			text-shadow: 2px 2px #333;
			position: absolute;
			bottom: 0;
			right: 0;

			strong {
				display: block;
				font-weight: normal;
				text-transform: uppercase;
				padding-top: 3px;
			}
		}

		.party {
			text-transform: uppercase;
			position: absolute;
			// left: 0;
			top: 0;

		}

		.party em {
			font-style: normal;
			color: #fff;
			text-shadow: 2px 2px #333;
  		}
	}

}*/

.test {
	position: absolute;
	width: 900px;
	z-index: 999;
}

.candidate-list {
	margin-left: -5px;
	margin-right: -5px;
	height: 100%;
	overflow: hidden;
}
.candidate-list__item {
	background-color: blue;
	padding: 0 5px;
	float: left;
	width: 33.33333333%;
	// height: 100%;
	padding-bottom: 44.444444444%;
	height: 0;
	display: block;
}

.left-candidate {
	box-sizing: border-box;
	width: 300px;
	position: relative;
}

.testbox {
	position: relative;
	
	
width: 450px;

	

	z-index: 999;
	
}

.testbox .item {
	background: linear-gradient(45deg, #ce2029 35%,#ce2029 35%,lighten(#ce2029, 10%) 76%);
	
	padding-bottom: 44.444444444%;
	display: block;
	overflow: hidden;
	height: 0;
}
.testbox--con {
	background: linear-gradient(45deg, #34439a 35%,#34439a 35%,lighten(#34439a, 10%) 76%);
	// top: 200px;
}


// @keyframes slide-in {
// 	from {
// 		opacity: 0;
// 	}
// 	to {
// 		opacity: 1;
// 	}
// }

// .candidate:nth-child(1) {animation: slide-in 2s .5s cubic-bezier(0, .9, .3, 1) forwards;}
// .candidate:nth-child(2) {animation: slide-in 2s .6s cubic-bezier(0, .9, .3, 1) forwards;}
// .candidate:nth-child(3) {animation: slide-in 2s .7s cubic-bezier(0, .9, .3, 1) forwards;}
// .candidate:nth-child(4) {animation: slide-in 2s .8s cubic-bezier(0, .9, .3, 1) forwards;}

// .candidate:nth-child(1) {animation: slide-in 2s .5s cubic-bezier(0, .9, .3, 1) forwards;}
// .candidate:nth-child(2) {animation: slide-in 2s .6s cubic-bezier(0, .9, .3, 1) forwards;}
// .candidate:nth-child(3) {animation: slide-in 2s .7s cubic-bezier(0, .9, .3, 1) forwards;}
// .candidate:nth-child(4) {animation: slide-in 2s .8s cubic-bezier(0, .9, .3, 1) forwards;}
// .candidate:nth-child(5) {animation: slide-in 2s .8s cubic-bezier(0, .9, .3, 1) forwards;}

// .candidate {animation: slide-in 2s .5s cubic-bezier(0, .9, .3, 1) forwards;}